import React, { useState } from 'react';
import { useClient } from '../App';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { Button, IconButton } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import VideocamIcon from '@mui/icons-material/Videocam';

const Controls = (props) => {
  const client = useClient();
  const { tracks, setStart, setInCall } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
  };

  return (
    <div className="controls">
      <IconButton
        onClick={() => mute("audio")}
      >
        {!trackState.audio ? <MicOffIcon color="error" fontSize="large" /> : <MicIcon fontSize="large" />}
      </IconButton>
      <IconButton
        onClick={() => mute("video")}>
        {!trackState.video ? <VideocamOffIcon color="error" fontSize="large" /> : <VideocamIcon fontSize="large" />}
      </IconButton>
      {<IconButton color="error" size="large" onClick={() => leaveChannel()}>
        <CallEndIcon fontSize="large" />
      </IconButton>}
    </div>
  );
};

export default Controls;