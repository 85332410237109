import React, { useEffect, useState } from "react";
import {
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";

import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-react";

import VideoCall from "./Components/VideoCall";

import './App.css'

const config = {
  mode: "rtc", codec: "vp8",
};

export const useClient = createClient(config);
export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

export const appId = '49eec95ac1944cd589b655e4b4f61333'; //ENTER APP ID HERE
export const token = '00649eec95ac1944cd589b655e4b4f61333IACKehzjT2UdZyEkk0cIXo3q9ZKNwVMmwnTtirS0Tr8ptwiyUV0AAAAAEABJ0h4OJwb+YQEAAQAmBv5h';

const App = () => {
  const [inCall, setInCall] = useState(true);
  const [channelName, setChannelName] = useState("manipal-test");
  return (
    <div className="main">
      <div className="heading">
        <img src="/manipal-logo.gif" style={{ width: "210px", height: "60px" }} />
        <h1>Manipal Hospitals</h1>
      </div>
      {inCall ? (
        <VideoCall setInCall={setInCall} channelName={channelName} />
      ) : (
        <ChannelForm setInCall={setInCall} setChannelName={setChannelName} />
      )}
    </div>
  );
};


const ChannelForm = (props) => {
  const { setInCall, setChannelName } = props;

  return (
    <form className="join">
      {appId === '' && <p style={{ color: 'red' }}>Please enter your Agora App ID in App.tsx and refresh the page</p>}
      <input type="text"
        placeholder="Enter Channel Name"
        onChange={(e) => setChannelName(e.target.value)}
      />
      <button onClick={(e) => {
        e.preventDefault();
        setInCall(true);
      }}>
        Join
      </button>
    </form>
  );
};

export default App;