import { AgoraVideoPlayer } from 'agora-rtc-react';
import React from 'react';

export default function Videos(props) {
    const { users, tracks } = props;

    return (
        <div>
            <div id="videos" className="relative">
                <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} />
                {users.length > 0 &&
                    users.map((user) => {
                        if (user.videoTrack) {
                            return (
                                <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} key={user.uid} />
                            );
                        } else return null;
                    })}
            </div>
        </div>
    );
};
